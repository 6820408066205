import { Component, OnInit } from "@angular/core";
import { UserService } from "../../services/user/user.service";
import { Router } from "@angular/router";
import { ImagesService } from "app/services/helper/images.service";
import { ProjectService } from "app/services/project/project.service";

@Component({
	selector: "app-complete-data",
	templateUrl: "./complete-data.component.html",
	styleUrls: ["./complete-data.component.scss"],
})
export class CompleteDataComponent implements OnInit {
	terms: boolean = false;

	messageError: boolean = false;
	message: string = "";

	user: any;
	project: any;
	constructor(
		private userService: UserService,
		private router: Router,
		public imageSvc: ImagesService,
		private projectSvc: ProjectService,
	) {}

	ngOnInit(): void {
		this.getDataUser();
		this.projectSvc.getProjectConfig().subscribe(
			(data) => {
				this.project = data;
				console.log(data);
			},
			(err) => {},
		);
	}

	getDataUser() {
		this.user = {
			nombre: "",
			apellidos: "",
			fecha: "",
			email: "",
		};

		this.userService.getDataUser().subscribe(
			(data) => {
				let dataUser = JSON.stringify(data);

				let json = JSON.parse(dataUser);

				console.log(json.firstName);
				console.log(json.lastName);
				var date = new Date(json.birthdate);

				let mes = (date.getMonth() + 1).toString().padStart(2, "0");
				let dia = date.getDate().toString().padStart(2, "0");
				let ano = new Date(json.birthdate).getFullYear();

				let fecha = ano + "-" + mes + "-" + dia;

				console.log(fecha);

				console.log(json.email);

				this.user = {
					nombre: json.firstName,
					apellidos: json.lastName,
					fecha: fecha,
					email: json.email,
				};
			},
			(error) => {
				console.log(error);
			},
		);
	}

	sendData() {
		let nombre = (<HTMLInputElement>document.getElementById("nombre")).value;
		let apellidos = (<HTMLInputElement>document.getElementById("apellidos")).value;
		let fecha = (<HTMLInputElement>document.getElementById("fecha")).value;
		let email = (<HTMLInputElement>document.getElementById("email")).value;
		let password = (<HTMLInputElement>document.getElementById("password")).value;

		console.log(nombre);
		console.log(apellidos);
		console.log(fecha);
		console.log(email);
		console.log(password);

		let terminos: number;

		if (this.terms == false) {
			terminos = 0;
		} else {
			terminos = 1;
		}

		if (terminos == 1) {
			this.userService.modifyDataUser(nombre, apellidos, fecha, email, password, terminos).subscribe(
				(data) => {
					this.router.navigate(["/inicio"], { replaceUrl: true });
				},
				(error) => {},
			);
		} else {
			this.messageError = true;
			this.message = "Debe aceptar los terminos y condiciones para continuar";
		}
	}
}
