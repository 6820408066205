<link
	href="https://fonts.googleapis.com/css2?family=Asap:wght@600&family=Quicksand&family=Raleway:wght@600&family=Roboto&display=swap"
	rel="stylesheet"
/>

<div style="margin-left: 30%">
	<img style="width: 95%; max-width: 500px" [src]="imageSvc.getLogo()" />

	<div *ngIf="project" style="margin-left: 15%">
		<h1>{{ "confirmData.title" | translate }}</h1>
		<p class="texto">{{ "confirmData.subtitle" | translate: { "0": project.name } }}</p>

		<!-- <p class="texto">{{ '' | translate }}Por favor,necesitamos que completes y confirmes <br> tus datos antes de acceder a Feedbalia por primera  <br> vez.</p> -->

		<label class="acciones">{{ "confirmData.field.title.personalData" | translate }}</label>

		<br />

		<div class="input">
			<span class="fa fa-user"></span>
			<input type="text" placeholder="{{ 'confirmData.field.name' | translate }}" value="{{ this.user.nombre }}" id="nombre" />
		</div>

		<br />

		<div class="input">
			<span class="fa fa-user"></span>
			<input type="text" placeholder="{{ 'confirmData.field.surname' | translate }}" value="{{ this.user.apellidos }}" id="apellidos" />
		</div>

		<br />

		<label class="acciones">{{ "confirmData.field.title.birthday" | translate }}</label>

		<div class="input">
			<span class="fa fa-calendar"></span>
			<input type="date" value="{{ this.user.fecha }}" id="fecha" />
		</div>

		<br />

		<label class="acciones">{{ "confirmData.field.title.email" | translate }}</label>

		<div class="input">
			<span class="fa fa-envelope"></span>
			<input type="test" placeholder="{{ 'confirmData.field.email' | translate }}" value="{{ this.user.email }}" />
		</div>

		<br />

		<div class="input">
			<span class="fa fa-envelope"></span>
			<input type="test" placeholder="{{ 'confirmData.field.email_repeat' | translate }}" value="{{ this.user.email }}" id="email" />
		</div>

		<br />

		<label class="acciones">{{ "confirmData.field.title.password" | translate }}</label>

		<div class="input">
			<span class="fa fa-eye-slash"></span>
			<input type="password" placeholder="{{ 'confirmData.field.password' | translate }}" />
		</div>

		<br />

		<div class="input">
			<span class="fa fa-eye-slash"></span>
			<input type="password" placeholder="{{ 'confirmData.field.password_repeat' | translate }}" id="password" />
		</div>

		<br />

		<input [(ngModel)]="terms" type="checkbox" />
		<label
			>{{ "confirmData.terms" | translate }}
			<a [routerLink]="['/legal-terms']" style="text-decoration: none">{{ "confirmData.terms2" | translate }}</a></label
		>

		<br />
		<br />

		<button (click)="sendData()" class="block">{{ "btn.continue" | translate }}</button>

		<br />

		<div *ngIf="this.messageError" class="alert alert-warning" role="alert">
			{{ this.message }}
		</div>

		<a [routerLink]="['/login']" style="margin-left: 45%; text-decoration: none">{{ "btn.exit" | translate }}</a>
	</div>
</div>
